<template>
    <div class="guide">
        <GuidesMenu :title="currentPath" :appGuides="brandConfig.appGuids"/>
        <div class="guide-container">
            <GuidesHeader :title="mainTitle || currentPath" :subtext="subtext" />
            <div class="guide-info">
                <h1 class="guide-header">{{ header }}</h1>
                <slot />
                <GuidesButtons :appGuides="brandConfig.appGuids"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        header: String,
        subtext: String,
        mainTitle: String
    },
    components: {
        GuidesMenu: () => import('@/components/GuidesMenu.vue'),
        GuidesHeader: () => import('@/components/GuidesHeader.vue'),
        GuidesButtons: () => import('@/components/GuidesButtons.vue')
    },
    computed: {
        currentPath() {
            return this.$router.history.current.name
        },
        ...mapGetters(['brandConfig'])
    }
};
</script>

<style scoped>
h1,
h2,
p,
a,
span,
ul,
ol,
button {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.guide {
    display: flex;
    background-color: #141417;
    min-height: 100vh;
}

.guide-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 32px;
    padding-left: 270px;
}

.guide-info {
    width: 80%;
    margin-top: 40px;
}

.guide-header {
    font-size: 20px;
    font-weight: 600;
    line-height: 25.2px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .guide-container {
        padding: 24px 16px;
        margin-top: 70px;
    }
    .guide-info{
        width: 100%;
    }
}
</style>
